import React from 'react';
import PropTypes from 'prop-types';
import setCharacterClass from 'utils/setCharacterClass';

import {
  Description,
  Value,
  Wrapper,
  BigTextWrapper,
} from './InfoBox.styles';

const InfoBox = ({ bigText, text }) => {
  const finalBigText = bigText[bigText.length - 1] === '+' ? setCharacterClass(bigText, '+', 'specialChar') : bigText;

  return (
    <Wrapper>
      <BigTextWrapper>
        <Value dangerouslySetInnerHTML={{ __html: finalBigText }} />
      </BigTextWrapper>
      <Description>{text}</Description>
    </Wrapper>
  );
};

InfoBox.propTypes = {
  bigText: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default InfoBox;
