const setCharacterClass = (text, characterToReplace, className) => {
  if (!text || !characterToReplace || !className) {
    return text;
  }

  const textArray = text.split('');

  const newText = textArray.map((char) => {
    if (char !== characterToReplace) {
      return char;
    }

    return `<span class="${className}">${char}</span>`;
  });

  return newText.join('');
};

export default setCharacterClass;
