import styled from 'styled-components';
import { ContainerMain } from 'utils/styles';
import wrapperBg from 'static/img/infoBoxBg.png';

const Wrapper = styled.div`
  background-image: url(${wrapperBg});
  background-repeat: repeat;
  margin: 20px 0 25px 0;
  box-shadow: 0 0 20px -10px rgba(${(props) => props.theme.shadowColor});

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: 20px 0 0 0;
  }
`;

const Container = styled(ContainerMain)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  min-height: 235px;
  background: white;
  padding-top: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 5px;
    padding: 0;
    width: calc(100% - 40px);
  }
`;

export { Wrapper, Container };
