import styled from 'styled-components';

const OffersListWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 22px;
`;

const OffersListUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 0;
`;

export { OffersListWrapper, OffersListUl };
