import { defineMessages } from 'react-intl';

export default defineMessages({
  realizationDate: {
    id: 'OffersListItem.realizationDate',
    defaultMessage: 'termin realizacji: ',
  },
  address: {
    id: 'OffersListItem.address',
    defaultMessage: 'adres: ',
  },
  checkAvailableLocals: {
    id: 'OffersListItem.checkAvailableLocals',
    defaultMessage: 'sprawdź dostępne lokale użytkowe',
  },
});
